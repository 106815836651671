.page-home {
    .features-item {
        background-color: $color-grey;
        border-radius: 20px;
        height: 100%;
        padding-left: 1em;
        padding-right: 1em;

        .features-icon {
            width: 180px;
            height: 150px;
            // border-radius: 20px;
            // background-color: $color-white;
        }

        .features-description {
            font-family: "Noto Sans Display";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            text-align: center;
            color: $color-lightgrey;
        }
    }

    .home-about-text {
        font-family: "Noto Sans Display";
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 150%;
        letter-spacing: 0.38px;
        color: $color-light-black;
        padding: 24px 24px 100px 24px;
        background-color: $color-grey;
        border-radius: 20px;
    }

    .main-explorer {
        margin-top: -100px;
    }

    .about-list-icon-ok {
        background-image: url("/assets/images/assignment_turned_in.svg");
        width: 25px;
        height: 25px;
        display: block;
    }

    .home-notice {
        font-family: "Noto Sans Display";
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 16px;
        color: $color-lightgrey;
    }

    .home-hero {
        height: 50vh;
    }

    .home-hero-form {
        z-index: 100;
        height: 50vh;
    }

    .hero-bg {
        // position: absolute;
        margin-top: -50vh;
        height: 50vh;
        z-index: 1;
    }

    .hero-form-block {
        padding: 24px;
        background: $color-white;
        box-shadow: 10px 10px 14px rgba(4, 42, 43, 0.1);
        border-radius: 5px;
        z-index: 200;
    }
}
