.breadcrumbs {
    .breadcrumbs-start {
        font-family: "Noto Sans Display";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: -0.32px;
        color: $color-black;
    }

    .breadcrumbs-end {
        font-family: "Noto Sans Display";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: -0.32px;
        color: $color-lightgrey;
    }
}
