.page-show-entity {
    .grey-title {
        color: $color-lightgrey;
    }

    .black-description {
        color: $color-dark;
        font-weight: bold !important;
    }

    .table-header {
        color: $color-white;
        background-color: $color-lightgrey;
        border-radius: 5px;
        font-family: "Noto Sans Display";
        font-weight: bold;
    }
}
