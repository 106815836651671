.btn-primary {
    color: $color-white;
    background-color: $color-primary;
    border-color: $color-primary;

    font-family: "Noto Sans Display";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.38px;

    border-radius: 4px;

    &:hover {
        background-color: $color-primary-hover;
        border-color: $color-primary;
    }

    &:active {
        background-color: $color-primary-hover !important;
        border-color: $color-primary;
    }

    &:focus {
        background-color: $color-primary-hover !important;
        border-color: $color-primary;
    }

    &:disabled {
        background-color: $color-lightgrey !important;
        border-color: $color-lightgrey;
        color: $color-grey;
    }
}

.btn-large {
    color: $color-white;
    background-color: $color-primary;
    border-color: $color-primary;

    font-family: "Noto Sans Display";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 27px;
    letter-spacing: 0.38px;

    border-radius: 4px;
}

.btn-small {
    color: $color-white;
    background-color: $color-primary;
    border-color: $color-primary;

    font-family: "Noto Sans Display";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.38px;

    border-radius: 5px;

    &:hover {
        background-color: $color-primary-hover;
        border-color: $color-primary;
        color: $color-white;
    }
}

.btn-small-outline {
    color: $color-black;
    background-color: $color-white;
    border-color: $color-black;
    font-family: "Noto Sans Display";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.38px;
    border-radius: 5px;

    &:hover {
        background-color: $color-black;
        color: $color-white;
    }
}

.btn-small-outline-red {
    color: $color-primary;
    background-color: $color-white;
    border-color: $color-primary;
    font-family: "Noto Sans Display";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.38px;
    border-radius: 5px;

    &:hover {
        background-color: $color-primary-hover;
        color: $color-white;
    }
}

.btn-outline {
    color: $color-black;
    background-color: $color-white;
    border-color: $color-black;

    font-family: "Noto Sans Display";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.38px;
    border-radius: 4px;

    &:hover {
        background-color: $color-black;
        color: $color-white;
    }
}

.btn-check {
    &:checked + .btn {
        background-color: $color-primary;
        border-color: $color-primary;
    }
}

.btn-outline-primary {
    border-color: $color-grey;
    color: $color-grey;

    &:hover {
        background-color: $color-grey !important;
        border-color: $color-grey !important;
        color: $color-white !important;
    }

    &.active {
        background-color: $color-primary !important;
        border-color: $color-primary !important;
        color: $color-white !important;
    }
}

// .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active
