.page-contacts {
    .contact-item {
        background-color: $color-grey;
        border-radius: 20px;

        .contact-icon {
            width: 120px;
            height: 120px;
            border-radius: 60px;
            background-color: $color-white;
        }

        .contact-description {
            font-family: "Noto Sans Display";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            text-align: center;
            color: $color-lightgrey;
        }
    }
}
