@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Display:wght@300;400;600;700&display=swap");

body {
    font-family: "Noto Sans Display", sans-serif;
    color: $color-dark;
    line-height: 150%;
    font-size: 14px;
}

input,
textarea,
select,
button {
    font-family: "Noto Sans Display", sans-serif;
    font-size: $size-16px;
    line-height: 1.2;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Noto Sans Display", sans-serif;
    margin: 0 0 $size-14px 0;
    color: $color-black;
    font-weight: bold !important;
}
