a {
    font-family: "Noto Sans Display";
    font-style: normal;
    font-weight: 400;
    // font-size: 14px;
    line-height: 22px;
    text-decoration: none;
    color: $color-primary;
    outline: 0;

    &:hover,
    &:focus {
        color: $color-red;
        border-bottom-color: transparent;
    }
}

.slogan {
    font-family: "Noto Sans Display";
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 150%;
    text-align: center;
    letter-spacing: 0.38px;
    color: $color-dark;
}

.atext-link {
    font-family: "Noto Sans Display";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: right;
    letter-spacing: -0.32px;
    color: $color-dark;

    &:hover {
        color: $color-primary-hover;
    }
}

.page-item {
    &:first-child {
        .page-link {
            color: $color-light-black !important;
        }
    }
    &:not(:first-child) {
        .page-link {
            color: $color-light-black !important;
        }
    }
}

.page-item.disabled {
    &:first-child {
        .page-link {
            color: $color-light-black !important;
            opacity: 0.5;
        }
    }
    &:not(:first-child) {
        .page-link {
            color: $color-light-black !important;
            opacity: 0.5;
        }
    }
}

.page-item.active {
    .page-link {
        color: $color-white !important;
        background-color: $color-light-black !important;
        border: 1px solid $color-light-black;
    }
}

.view-link {
    font-size: 14px;
}
