#header {
    .nav {
        .atext-nav {
            font-family: "Noto Sans Display";
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 24px;
            color: #878793;

            &:hover {
                color: $color-primary-hover;
            }
        }
        .atext-nav-active {
            font-family: "Noto Sans Display";
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 24px;
            color: #042a2b;
        }
    }

    .nav-tabs {
        .nav-item {
            .nav-link {
                color: #042a2b !important;
            }

            .nav-link.active {
                color: $color-primary !important;
            }
        }
    }

    .lang-link {
        font-family: "Noto Sans Display";
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;
        letter-spacing: -0.32px;
        color: $color-black;

        &:hover {
            color: $color-primary-hover;
        }
    }
    .lang-link-active {
        color: $color-primary;
    }
}

#footer {
    .nav {
        .atext-nav {
            font-family: "Noto Sans Display";
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 24px;
            color: $color-white;

            &:hover {
                color: $color-primary;
            }
        }
    }
}
