.page-personal {
    .personal-navbar {
        border-bottom: 1px solid $color-grey;
    }

    .nav-link {
        font-family: "Noto Sans Display";
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 150%;
        letter-spacing: 0.38px;
        color: $color-grey;
        padding: 14px;

        &:hover {
            color: $color-primary;
        }
    }

    .nav-link.active {
        border: none;
        border-bottom: 1px solid $color-primary;
    }

    .page-description-email {
        color: $color-lightgrey;
    }

    .page-description-date-updated {
        font-family: "Noto Sans Display";
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: 0.38px;
        color: $color-grey;
    }

    .entity-details {
        font-family: "Noto Sans Display";
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 150%;
        letter-spacing: 0.38px;
        text-align: start;
        color: $color-grey;

        &:hover {
            background-color: $color-border;
        }
    }

    .page-balance {
        background-color: $color-border;
        border-radius: 5px;
        color: $color-light-black;
        font-family: "Noto Sans Display";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
    }

    .personal-has-no-reports {
        color: $color-lightgrey;
    }

    .personal-transaction-fill {
        color: $color-green;
    }
}
