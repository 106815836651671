.page-crm {
    .personal-navbar {
        border-bottom: 1px solid $color-grey;
    }

    .entity-details {
        font-family: "Noto Sans Display";
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 150%;
        letter-spacing: 0.38px;
        text-align: start;
        color: $color-grey;

        &:hover {
            background-color: $color-border;
        }
    }

    .nav-link {
        font-family: "Noto Sans Display";
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: 0.38px;
        color: $color-grey;
        padding: 8px;
        border-bottom: 1px solid $color-lightgrey;

        &:hover {
            color: $color-primary;
            border-bottom: 1px solid $color-primary;
        }
    }

    .nav-link.active {
        color: $color-dark;
        background-color: $color-border;
        border: none;
        border-bottom: 1px solid $color-dark;
    }

    .page-description {
        font-family: "Noto Sans Display";
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
    }

    .page-description-email {
        color: $color-lightgrey;
    }

    .page-description-date-updated {
        font-family: "Noto Sans Display";
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: 0.38px;
        color: $color-grey;
    }

    .orders {
        .order {
            border: 1px solid $color-lightgrey;
            border-radius: 5px;

            &:hover {
                background-color: $color-lightgrey;
            }

            h5 {
                text-align: start;
            }

            .order-icon {
                background-image: url("/assets/images/order-icon.svg");
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center center;
                width: 36px;
                height: 36px;
                display: block;
            }

            .arrow-icon {
                background-image: url("/assets/images/keyboard_arrow_right.svg");
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center center;
                width: 36px;
                height: 36px;
                display: block;
            }

            .badge {
                font-family: "Noto Sans Display";
                font-size: 10px;
            }
        }
    }

    .personal-has-no-reports {
        color: $color-lightgrey;
    }

    .personal-transaction-fill {
        color: $color-green;
    }

    .filter-block {
        background-color: $color-border;
        border-radius: 5px;
    }

    .report-table-header {
        color: $color-white;
        background-color: $color-lightgrey;
        border-radius: 5px;
        font-family: "Noto Sans Display";
        font-weight: bold;
    }

    .stats-block {
        background-color: $color-border;
        border-radius: 5px;

        .stats-description {
            font-family: "Noto Sans Display";
            font-weight: 300;
            font-size: 10px;
            text-transform: uppercase;
        }

        .stats-title {
            font-family: "Noto Sans Display";
            font-weight: bold;
            font-size: 14px;
            color: $color-dark;
        }

        .stats-number {
            font-family: "Noto Sans Display";
            font-weight: bold;
            font-size: 38px;
            color: $color-light-black;
        }
    }

    .stats-block-blue {
        background-color: $color-blue;
    }

    .gallery-item {
        list-style: none;
        width: 140px;
        height: 140px;
        margin: 5px;
        border: 1px solid #dee2e6;
        border-radius: 5px;
        background-color: #fff;
        position: relative;
        font-size: 8px;

        img {
            list-style: none;
            max-width: 120px;
            max-height: 120px;
        }
    }
    .gallery-item-container {
        width: 100%;
        height: 100%;

        a {
            font-size: 14px;
        }
    }

    .gallery-remove {
        display: block;
        position: absolute;
        top: 3px;
        right: 3px;
        background-color: #f3421c;
        color: #fff;
        width: 16px;
        height: 16px;
        text-align: center;
        /* vertical-align: middle; */
        border-radius: 8px;
        cursor: pointer;
        -webkit-box-shadow: 3px 3px 4px 0px rgba(34, 60, 80, 0.2);
        -moz-box-shadow: 3px 3px 4px 0px rgba(34, 60, 80, 0.2);
        box-shadow: 3px 3px 4px 0px rgba(34, 60, 80, 0.2);
    }
}

.hoverable {
    &:hover {
        background-color: $color-yellow-light;
    }
}
