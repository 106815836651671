footer {
    .footer-checkvin {
        background-color: $color-light-black;

        .footer-checkvin-text {
            color: $color-white;
            font-family: "Noto Sans Display";
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 27px;
            text-align: right;
            letter-spacing: 0.38px;
        }
    }

    .footer-main {
        background-color: $color-dark;

        .footer-social-link {
            font-family: "Noto Sans Display";
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 150%;
            color: $color-white;
            text-decoration: none;
            margin-right: 16px;

            &:hover {
                color: $color-primary;
            }
        }

        .footer-copyright {
            font-family: "Noto Sans Display";
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 150%;
            text-align: right;
            color: $color-grey;
            opacity: 0.5;
        }
    }
}
