$color-primary: #202d57; //#a08869;
$color-primary-hover: #485c9e; //#c6a982;
$color-black: #2f3d41;
$color-dark: #2f3d41;
$color-light-black: #2d2d2d;
$color-grey: #383c45;
$color-lightgrey: #aeaeae;
$color-white: #ffffff;
$color-border: #e9eff0;

$color-green: #00bfb2;
$color-blue: #cdedf6;
$color-yellow: #f8e71c;
$color-yellow-light: #fff585;
$color-orange: #ffa100;
$color-red: #fd3c1c;
$color-danger: #ffece9;
$color-success: #ebf9f7;

$width: 1440px;
$block-radius: 20px;

$size-4px: 0.286em;
$size-8px: 0.571em;
$size-10px: 0.714em;
$size-12px: 0.857em;
$size-14px: 1em;
$size-16px: 1.143em;
$size-18px: 1.286em;
$size-20px: 1.429em;
$size-22px: 1.571em;
$size-24px: 1.714em;
$size-26px: 1.857em;
$size-28px: 2em;
$size-30px: 2.143em;
$size-36px: 2.571em;
$size-56px: 4em;
