body,
html {
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
}

body {
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    background: $color-white;
}

h1,
h2,
h3,
h4,
h5 {
    margin: 0 0 0.2em 0;
}

h5 {
    font-family: "Noto Sans Display";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.38px;
    color: $color-dark;
}

.clearfix {
    clear: both;
}

.hide,
.dn {
    display: none;
}

.page-description {
    font-family: "Noto Sans Display";
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 150%;
    letter-spacing: 0.38px;
    color: $color-dark;
}

.check-vin-sidebar {
    background-color: $color-blue;
    border-radius: 20px;
}

.check-vin-sidebar-text {
    font-family: "Noto Sans Display";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.38px;

    color: $color-light-black;
}

.about-text {
    font-family: "Noto Sans Display";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: $color-lightgrey;
}

.row-grey {
    background-color: $color-grey;
}

.row-white {
    border-bottom: 1px solid $color-border;
}

.text-primary {
    color: $color-dark !important;
}
