@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Display:wght@300;400;600;700&display=swap");
body {
  font-family: "Noto Sans Display", sans-serif;
  color: #2f3d41;
  line-height: 150%;
  font-size: 14px;
}

input,
textarea,
select,
button {
  font-family: "Noto Sans Display", sans-serif;
  font-size: 1.143em;
  line-height: 1.2;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Noto Sans Display", sans-serif;
  margin: 0 0 1em 0;
  color: #2f3d41;
  font-weight: bold !important;
}

a {
  font-family: "Noto Sans Display";
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  text-decoration: none;
  color: #202d57;
  outline: 0;
}
a:hover, a:focus {
  color: #fd3c1c;
  border-bottom-color: transparent;
}

.slogan {
  font-family: "Noto Sans Display";
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.38px;
  color: #2f3d41;
}

.atext-link {
  font-family: "Noto Sans Display";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: right;
  letter-spacing: -0.32px;
  color: #2f3d41;
}
.atext-link:hover {
  color: #485c9e;
}

.page-item:first-child .page-link {
  color: #2d2d2d !important;
}
.page-item:not(:first-child) .page-link {
  color: #2d2d2d !important;
}

.page-item.disabled:first-child .page-link {
  color: #2d2d2d !important;
  opacity: 0.5;
}
.page-item.disabled:not(:first-child) .page-link {
  color: #2d2d2d !important;
  opacity: 0.5;
}

.page-item.active .page-link {
  color: #ffffff !important;
  background-color: #2d2d2d !important;
  border: 1px solid #2d2d2d;
}

.view-link {
  font-size: 14px;
}

.form-control ::-webkit-input-placeholder {
  text-align: center;
}
.form-control :-moz-placeholder {
  /* Firefox 18- */
  text-align: center;
}
.form-control ::-moz-placeholder {
  /* Firefox 19+ */
  text-align: center;
}
.form-control :-ms-input-placeholder {
  text-align: center;
}

.required-label:after {
  content: "*";
  color: red;
}

footer .footer-checkvin {
  background-color: #2d2d2d;
}
footer .footer-checkvin .footer-checkvin-text {
  color: #ffffff;
  font-family: "Noto Sans Display";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  text-align: right;
  letter-spacing: 0.38px;
}
footer .footer-main {
  background-color: #2f3d41;
}
footer .footer-main .footer-social-link {
  font-family: "Noto Sans Display";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 150%;
  color: #ffffff;
  text-decoration: none;
  margin-right: 16px;
}
footer .footer-main .footer-social-link:hover {
  color: #202d57;
}
footer .footer-main .footer-copyright {
  font-family: "Noto Sans Display";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  text-align: right;
  color: #383c45;
  opacity: 0.5;
}

body,
html {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
}

body {
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  background: #ffffff;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0 0 0.2em 0;
}

h5 {
  font-family: "Noto Sans Display";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.38px;
  color: #2f3d41;
}

.clearfix {
  clear: both;
}

.hide,
.dn {
  display: none;
}

.page-description {
  font-family: "Noto Sans Display";
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 150%;
  letter-spacing: 0.38px;
  color: #2f3d41;
}

.check-vin-sidebar {
  background-color: #cdedf6;
  border-radius: 20px;
}

.check-vin-sidebar-text {
  font-family: "Noto Sans Display";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.38px;
  color: #2d2d2d;
}

.about-text {
  font-family: "Noto Sans Display";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #aeaeae;
}

.row-grey {
  background-color: #383c45;
}

.row-white {
  border-bottom: 1px solid #e9eff0;
}

.text-primary {
  color: #2f3d41 !important;
}

#header .nav .atext-nav {
  font-family: "Noto Sans Display";
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 24px;
  color: #878793;
}
#header .nav .atext-nav:hover {
  color: #485c9e;
}
#header .nav .atext-nav-active {
  font-family: "Noto Sans Display";
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 24px;
  color: #042a2b;
}
#header .nav-tabs .nav-item .nav-link {
  color: #042a2b !important;
}
#header .nav-tabs .nav-item .nav-link.active {
  color: #202d57 !important;
}
#header .lang-link {
  font-family: "Noto Sans Display";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.32px;
  color: #2f3d41;
}
#header .lang-link:hover {
  color: #485c9e;
}
#header .lang-link-active {
  color: #202d57;
}

#footer .nav .atext-nav {
  font-family: "Noto Sans Display";
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 24px;
  color: #ffffff;
}
#footer .nav .atext-nav:hover {
  color: #202d57;
}

.breadcrumbs .breadcrumbs-start {
  font-family: "Noto Sans Display";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: -0.32px;
  color: #2f3d41;
}
.breadcrumbs .breadcrumbs-end {
  font-family: "Noto Sans Display";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: -0.32px;
  color: #aeaeae;
}

.btn-primary {
  color: #ffffff;
  background-color: #202d57;
  border-color: #202d57;
  font-family: "Noto Sans Display";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.38px;
  border-radius: 4px;
}
.btn-primary:hover {
  background-color: #485c9e;
  border-color: #202d57;
}
.btn-primary:active {
  background-color: #485c9e !important;
  border-color: #202d57;
}
.btn-primary:focus {
  background-color: #485c9e !important;
  border-color: #202d57;
}
.btn-primary:disabled {
  background-color: #aeaeae !important;
  border-color: #aeaeae;
  color: #383c45;
}

.btn-large {
  color: #ffffff;
  background-color: #202d57;
  border-color: #202d57;
  font-family: "Noto Sans Display";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 27px;
  letter-spacing: 0.38px;
  border-radius: 4px;
}

.btn-small {
  color: #ffffff;
  background-color: #202d57;
  border-color: #202d57;
  font-family: "Noto Sans Display";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.38px;
  border-radius: 5px;
}
.btn-small:hover {
  background-color: #485c9e;
  border-color: #202d57;
  color: #ffffff;
}

.btn-small-outline {
  color: #2f3d41;
  background-color: #ffffff;
  border-color: #2f3d41;
  font-family: "Noto Sans Display";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.38px;
  border-radius: 5px;
}
.btn-small-outline:hover {
  background-color: #2f3d41;
  color: #ffffff;
}

.btn-small-outline-red {
  color: #202d57;
  background-color: #ffffff;
  border-color: #202d57;
  font-family: "Noto Sans Display";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.38px;
  border-radius: 5px;
}
.btn-small-outline-red:hover {
  background-color: #485c9e;
  color: #ffffff;
}

.btn-outline {
  color: #2f3d41;
  background-color: #ffffff;
  border-color: #2f3d41;
  font-family: "Noto Sans Display";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.38px;
  border-radius: 4px;
}
.btn-outline:hover {
  background-color: #2f3d41;
  color: #ffffff;
}

.btn-check:checked + .btn {
  background-color: #202d57;
  border-color: #202d57;
}

.btn-outline-primary {
  border-color: #383c45;
  color: #383c45;
}
.btn-outline-primary:hover {
  background-color: #383c45 !important;
  border-color: #383c45 !important;
  color: #ffffff !important;
}
.btn-outline-primary.active {
  background-color: #202d57 !important;
  border-color: #202d57 !important;
  color: #ffffff !important;
}

.accordion-item {
  border: none;
  border-bottom: 1px solid #aeaeae;
}

.accordion-button:not(.collapsed) {
  background-color: transparent;
  font-family: "Noto Sans Display";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.36px;
  color: #202d57;
}

.accordion-button.collapsed {
  background-color: transparent;
  font-family: "Noto Sans Display";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.36px;
  color: #2f3d41;
}

.accordion-body {
  background-color: #e9eff0;
  font-family: "Noto Sans Display";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.38px;
  color: #383c45;
}

.accordion-item-small .accordion-button.collapsed {
  font-size: 14px;
  line-height: 120%;
  padding: 10px;
}
.accordion-item-small .accordion-button:not(.collapsed) {
  font-size: 14px;
  line-height: 120%;
  padding: 10px;
}

.accordion-body-small {
  background-color: #e9eff0;
  font-size: 142px;
  line-height: 120%;
  padding: 0;
}

.page-home .features-item {
  background-color: #383c45;
  border-radius: 20px;
  height: 100%;
  padding-left: 1em;
  padding-right: 1em;
}
.page-home .features-item .features-icon {
  width: 180px;
  height: 150px;
}
.page-home .features-item .features-description {
  font-family: "Noto Sans Display";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #aeaeae;
}
.page-home .home-about-text {
  font-family: "Noto Sans Display";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.38px;
  color: #2d2d2d;
  padding: 24px 24px 100px 24px;
  background-color: #383c45;
  border-radius: 20px;
}
.page-home .main-explorer {
  margin-top: -100px;
}
.page-home .about-list-icon-ok {
  background-image: url("/assets/images/assignment_turned_in.svg");
  width: 25px;
  height: 25px;
  display: block;
}
.page-home .home-notice {
  font-family: "Noto Sans Display";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  color: #aeaeae;
}
.page-home .home-hero {
  height: 50vh;
}
.page-home .home-hero-form {
  z-index: 100;
  height: 50vh;
}
.page-home .hero-bg {
  margin-top: -50vh;
  height: 50vh;
  z-index: 1;
}
.page-home .hero-form-block {
  padding: 24px;
  background: #ffffff;
  box-shadow: 10px 10px 14px rgba(4, 42, 43, 0.1);
  border-radius: 5px;
  z-index: 200;
}

.page-contacts .contact-item {
  background-color: #383c45;
  border-radius: 20px;
}
.page-contacts .contact-item .contact-icon {
  width: 120px;
  height: 120px;
  border-radius: 60px;
  background-color: #ffffff;
}
.page-contacts .contact-item .contact-description {
  font-family: "Noto Sans Display";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #aeaeae;
}

.page-faq .car-background {
  background-color: #cdedf6;
  border-radius: 0 0 20px 20px;
  width: "100%";
  height: 100px;
  margin-top: -60px;
  z-index: 0;
}

.page-personal .personal-navbar {
  border-bottom: 1px solid #383c45;
}
.page-personal .nav-link {
  font-family: "Noto Sans Display";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.38px;
  color: #383c45;
  padding: 14px;
}
.page-personal .nav-link:hover {
  color: #202d57;
}
.page-personal .nav-link.active {
  border: none;
  border-bottom: 1px solid #202d57;
}
.page-personal .page-description-email {
  color: #aeaeae;
}
.page-personal .page-description-date-updated {
  font-family: "Noto Sans Display";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.38px;
  color: #383c45;
}
.page-personal .entity-details {
  font-family: "Noto Sans Display";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.38px;
  text-align: start;
  color: #383c45;
}
.page-personal .entity-details:hover {
  background-color: #e9eff0;
}
.page-personal .page-balance {
  background-color: #e9eff0;
  border-radius: 5px;
  color: #2d2d2d;
  font-family: "Noto Sans Display";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}
.page-personal .personal-has-no-reports {
  color: #aeaeae;
}
.page-personal .personal-transaction-fill {
  color: #00bfb2;
}

.page-show-entity .grey-title {
  color: #aeaeae;
}
.page-show-entity .black-description {
  color: #2f3d41;
  font-weight: bold !important;
}
.page-show-entity .table-header {
  color: #ffffff;
  background-color: #aeaeae;
  border-radius: 5px;
  font-family: "Noto Sans Display";
  font-weight: bold;
}

.page-crm .personal-navbar {
  border-bottom: 1px solid #383c45;
}
.page-crm .entity-details {
  font-family: "Noto Sans Display";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.38px;
  text-align: start;
  color: #383c45;
}
.page-crm .entity-details:hover {
  background-color: #e9eff0;
}
.page-crm .nav-link {
  font-family: "Noto Sans Display";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.38px;
  color: #383c45;
  padding: 8px;
  border-bottom: 1px solid #aeaeae;
}
.page-crm .nav-link:hover {
  color: #202d57;
  border-bottom: 1px solid #202d57;
}
.page-crm .nav-link.active {
  color: #2f3d41;
  background-color: #e9eff0;
  border: none;
  border-bottom: 1px solid #2f3d41;
}
.page-crm .page-description {
  font-family: "Noto Sans Display";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
}
.page-crm .page-description-email {
  color: #aeaeae;
}
.page-crm .page-description-date-updated {
  font-family: "Noto Sans Display";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.38px;
  color: #383c45;
}
.page-crm .orders .order {
  border: 1px solid #aeaeae;
  border-radius: 5px;
}
.page-crm .orders .order:hover {
  background-color: #aeaeae;
}
.page-crm .orders .order h5 {
  text-align: start;
}
.page-crm .orders .order .order-icon {
  background-image: url("/assets/images/order-icon.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  width: 36px;
  height: 36px;
  display: block;
}
.page-crm .orders .order .arrow-icon {
  background-image: url("/assets/images/keyboard_arrow_right.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  width: 36px;
  height: 36px;
  display: block;
}
.page-crm .orders .order .badge {
  font-family: "Noto Sans Display";
  font-size: 10px;
}
.page-crm .personal-has-no-reports {
  color: #aeaeae;
}
.page-crm .personal-transaction-fill {
  color: #00bfb2;
}
.page-crm .filter-block {
  background-color: #e9eff0;
  border-radius: 5px;
}
.page-crm .report-table-header {
  color: #ffffff;
  background-color: #aeaeae;
  border-radius: 5px;
  font-family: "Noto Sans Display";
  font-weight: bold;
}
.page-crm .stats-block {
  background-color: #e9eff0;
  border-radius: 5px;
}
.page-crm .stats-block .stats-description {
  font-family: "Noto Sans Display";
  font-weight: 300;
  font-size: 10px;
  text-transform: uppercase;
}
.page-crm .stats-block .stats-title {
  font-family: "Noto Sans Display";
  font-weight: bold;
  font-size: 14px;
  color: #2f3d41;
}
.page-crm .stats-block .stats-number {
  font-family: "Noto Sans Display";
  font-weight: bold;
  font-size: 38px;
  color: #2d2d2d;
}
.page-crm .stats-block-blue {
  background-color: #cdedf6;
}
.page-crm .gallery-item {
  list-style: none;
  width: 140px;
  height: 140px;
  margin: 5px;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  background-color: #fff;
  position: relative;
  font-size: 8px;
}
.page-crm .gallery-item img {
  list-style: none;
  max-width: 120px;
  max-height: 120px;
}
.page-crm .gallery-item-container {
  width: 100%;
  height: 100%;
}
.page-crm .gallery-item-container a {
  font-size: 14px;
}
.page-crm .gallery-remove {
  display: block;
  position: absolute;
  top: 3px;
  right: 3px;
  background-color: #f3421c;
  color: #fff;
  width: 16px;
  height: 16px;
  text-align: center;
  /* vertical-align: middle; */
  border-radius: 8px;
  cursor: pointer;
  -webkit-box-shadow: 3px 3px 4px 0px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 3px 3px 4px 0px rgba(34, 60, 80, 0.2);
  box-shadow: 3px 3px 4px 0px rgba(34, 60, 80, 0.2);
}

.hoverable:hover {
  background-color: #fff585;
}