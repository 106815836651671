.page-faq {
    .car-background {
        background-color: $color-blue;
        border-radius: 0 0 20px 20px;
        width: "100%";
        height: 100px;
        margin-top: -60px;
        z-index: 0;
    }
}
