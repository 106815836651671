.tabs {
}

.accordion-item {
    border: none;
    border-bottom: 1px solid $color-lightgrey;
}

.accordion-button:not(.collapsed) {
    background-color: transparent;

    font-family: "Noto Sans Display";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.36px;
    color: $color-primary;
}

.accordion-button.collapsed {
    background-color: transparent;

    font-family: "Noto Sans Display";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.36px;
    color: $color-dark;
}

.accordion-body {
    background-color: $color-border;
    font-family: "Noto Sans Display";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.38px;
    color: $color-grey;
}

.accordion-item-small {
    .accordion-button.collapsed {
        font-size: 14px;
        line-height: 120%;
        padding: 10px;
    }

    .accordion-button:not(.collapsed) {
        font-size: 14px;
        line-height: 120%;
        padding: 10px;
    }
}

.accordion-body-small {
    background-color: $color-border;
    font-size: 142px;
    line-height: 120%;
    padding: 0;
}
